import axios from "axios";
import { deleteFromStorage, getFromStorage } from "./storage";
import { constants } from "./helper";
import { openRoutes } from "../routes";


axios.defaults.baseURL = constants.baseUrl;


axios.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${getFromStorage("token")}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios({
  url: "api/subscription/status",
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getFromStorage("token")}`,
  },
})
  .then(async (response) => {})
  .catch(async (ex) => {
    localStorage.clear();
    deleteFromStorage("token");
    deleteFromStorage("userDetails");
    if(!getFromStorage('userDetails') && openRoutes?.find((obj)=> obj?.path == window.location.pathname && window.location.pathname !== "/bigul" && window.location.pathname !== "/webview")){
      window.location.href = "/login"; // Redirect to the login page
    }
    // if (window.location.pathname !== "/login") {
    //   window.location.href = "/login"; // Redirect to the login page
    // }
    // window.location.href = "/login";
    // window.location.reload()
  });
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response.status === 401) {
      localStorage.clear();
      // window.location.href = "/login";

    }

    return Promise.reject(err);
  }
);

export default axios;
